import Echo from 'laravel-echo';
import io from 'socket.io-client';
import { echoConfig } from '@/config/echo';

const echo = new Echo({
    broadcaster: 'socket.io',
    host: echoConfig.host,
    path: '/socket.io',
    transports: ['websocket'],
    secure: true,
    client: io,
    enabledTransports: ['ws', 'wss'],
});

export default echo;
